import { toast } from "react-hot-toast"
import { Success, Danger, Loading, Warning } from "infortech_modules/Componentes/Iconos";

export default function Alertas(tipo, mensaje, duracion) {
    let style;
    let className;
    let icon;
    duracion = duracion === undefined ? 4200 : duracion;

    switch (tipo) {
        case "s":
            style = {
                color: "#0D492D",
                font: "message-box",
                minWidth: "35%",
                textAlign: 'center',
                fontWeight: "bold",
            }
            className = "bg-success fs-6"
            icon = <Success />
            break;
        case "e":
            style = {
                color: "#F3AEB4",
                font: "message-box",
                minWidth: "35%",
                textAlign: 'center',
                fontWeight: "bold",
            }
            icon = <Danger />
            className = "icon-attention-circled bg-danger  fs-6"
            break;
        case "a":
            style = {
                color: "#000",
                font: "message-box",
                fontWeight: "bold",
                minWidth: "35%",
                textAlign: 'center',
            }
            icon = <Warning />
            className = "bg-warning fs-6"
            break;
        default:
            break;
    }


    return (
        <>
            {
                toast(mensaje, {
                    duration: duracion,
                    style: style,
                    className: className,
                    icon: icon,
                    ariaProps: {
                        role: 'status',
                        'aria-live': 'polite',
                    },
                })



            }
        </>
    )
};

export function AlertaPromesa(mensajeInicial, promesa, tipo, duracion) {
    let style;
    let icono;
    let className;

    tipo = tipo === undefined ? "e" : tipo;
    duracion = duracion === undefined ? 4200 : duracion;
    switch (tipo) {
        case "a":
            style = {
                color: "#000",
                font: "message-box",
                fontWeight: "bold",
                minWidth: "35%",
                textAlign: 'center',
            }
            icono = <Warning />
            className = "bg-warning fs-6"
            break;

        default:
            style = {
                color: "#F3AEB4",
                font: "message-box",
                minWidth: "35%",
                textAlign: 'center',
                fontWeight: "bold",
            };
            icono = <Danger />
            className = "bg-danger  fs-6"
            break;
    }

    return (
        toast.promise(
            promesa, {
            loading: mensajeInicial,
            success: (d) => d,
            error: (e) => e

        }, {
            className: 'fs-6',
            style: {
                color: "#000",
                font: "message-box",
                minWidth: "35%",
                textAlign: 'center',
                fontWeight: "bold",
            },
            icon: <Loading />,
            success: {
                duration: duracion,
                style: {
                    color: "#0D492D",
                    font: "message-box",
                    minWidth: "35%",
                    textAlign: 'center',
                    fontWeight: "bold",
                },
                className: "bg-success fs-6",
                icon: <Success />,
                ariaProps: {
                    role: 'status',
                    'aria-live': 'polite',
                },
            },
            error: {
                duration: duracion,
                style: style,
                className: className,
                icon: icono,
                ariaProps: {
                    role: 'status',
                    'aria-live': 'polite',
                },
            },
        },
        )
    )

}