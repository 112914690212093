import Alertas from "infortech_modules/Componentes/Alertas";

export default function EsNumero(array, array1) {
    let numeros = /^[0-9]+$/;
    let estado = "correcto";
    console.log(array);

    if (Array.isArray(array)) {
        if (Array.isArray(array1)) {
            for (let i = 0; i < array.length; i++) {
                if (!array[i].match(numeros)) {
                    Alertas("a", array1[i]);
                    estado = "error";
                    i = array.length;
                }
            }

            if (estado === "correcto") {
                return true
            } else {
                return false
            }
        } else {
            Alertas("e", "Error 4: Datos de Texto inválidos")
            return false;
        }

    } else {
        Alertas("e", "Error 3: Formato de número inválidos")
        return false;
    }
}