import Put from "infortech_modules/Crud/Put";
import { usuario } from "./Usuarios";




const EliminarToken = (iniciar) => { 
        Put({ url: '/salir', id: `id = ${usuario().id}`, data0: { onlweb: 0 }, table: 'usuarios' }).then((r) => {
            window.location.href="/";
            localStorage.clear();
           setTimeout(() => {            
            iniciar();
           }, 500);
        }).catch((f) => {
                
        })
    }
export default EliminarToken;