export const api = 'https://servertarjetas.groupbinarios.co';
//export const api = 'http://192.168.0.103:3010';

export const duplicate = (estado, datos, row, msje, ad) => {
    ad = ad === undefined ? ["",""] : ad;


    if (estado) {
        return {
            verificar: true,
            datos: datos,
            row: row,
            ad: ad,
            msje: msje,
        }
    } else {
        return { verificar: false }
    }
}

export const datosConsultarHistorial = (estado, tabla, fila, msje, adicional) => {
    if (estado) {
        return {
            direct: estado,
        }
    } else {
        return {
            verificar: false,
            tabla: tabla,
            row: fila,
            msje: msje,
            ad: adicional
        }
    }
}

export const header = () => {
    return {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.ingreso}`
    }
}

//export const upload = "http://localhost/Tarjetas";
export const upload = "https://tarjetavirtual.groupbinarios.co";
export const imagenes = {
    productos: `${upload}/Imagenes/Productos/`, servicios: `${upload}/Imagenes/Servicios/`, 
    logos: `${upload}/Imagenes/Logos/`, portada:`${upload}/Imagenes/Portada/`, perfil: `${upload}/Imagenes/Perfiles/Perfil/`,
    pportada:`${upload}/Imagenes/Perfiles/Portada/`
}

export const cambiarFormulario = (e, dato, setDato, setImg) => {
    if (e.target.files && e.target.files.length > 0) {
        const archivo = e.target.files[0];
        if (archivo.type.includes("image")) {
            setImg(archivo)
        }
    } else {
        setDato({
            ...dato,
            [e.target.name]: e.target.value
        })
    }
}

export const urlMaps = (coordendas, zoom) => {
    return `https://maps.google.com/?ll=${coordendas.lat},${coordendas.lng}&z=${zoom}`;
}

export default api;

