import Alertas from "infortech_modules/Componentes/Alertas";

export default function EsVacio(array, array1) {
    let estado = "correcto";
    if (Array.isArray(array)) {
        if (Array.isArray(array1)) {
            for (let i = 0; i < array.length; i++) {
                if (array[i] === "") {
                    Alertas("a", array1[i]);
                    estado = "error";
                    i = array.length;
                }
            }

            if (estado === "correcto") {
                return true
            } else {
                return false
            }
        } else {
            Alertas("e", "Error 2: Datos Invalidos");
            return false;
        }
    } else {
        Alertas("e", "Error 1: Datos Invalidos");
        return false;
    }

}