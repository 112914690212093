export function CrearEncabezado(datos, scope, claseFila, claseUniversal) {
    let estado = false;
    if (claseFila !== undefined) {
        if (claseFila.length > 0) {
            estado = true;
        } else {
            estado = false;
        }
    }
    const array = datos;

    if (Array.isArray(array)) {
        const value = array.map((value, i) => {
            let valor;
            if (estado) {
                if (i < claseFila.length) {
                    valor = claseFila[i] + " " + claseUniversal;
                } else {
                    valor = claseUniversal;
                }
            }

            return (
                <th scope={scope} key={i} className={valor} >{value}</th>
            )
        })

        return value;
    } else {
        return ""
    }

}

export function CreaarFila(datos, scope, clase, universal) {
    let estado = false;
    if (clase !== undefined) {
        if (clase.length > 0) {
            estado = true;
        } else {
            estado = false;
        }
    }

    const array = datos;

    if(Array.isArray(datos)){
        const value = array.map((value, i) => {
            let valor;
            if (estado) {
                if (i < clase.length) {
                    valor = clase[i] + " " + universal;
                } else {
                    valor = universal;
                }
            }
    
            return (
                <td key={i} className={valor} >{value}</td>
            )
        })
        return value;
    }else{
        return ""
    }
    
}