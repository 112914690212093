import jwtDecode from "jwt-decode"

export const usuario = () => {
    if (localStorage.ingreso) {
        return jwtDecode(localStorage.getItem('ingreso'))
    } else {
        return []
    }

}

export default function Permisos() {
    return {
        dashboard: 1,
        empresa: 2,
        tarjetaDatos: 3,
        usuarios: 4,
        productos: 5,
        servicios: 6,
        usuariosProductos: 7,
        usuariosServicios: 8,
        salir: 9,
        empresaDdatos: 11,
        empresaLogo: 12,
        empresaPortada: 13,
        empresaGeo: 14,
        tarjetaFoto: 16,
        tarjetaPortada: 17,

        //Add       
        empresaAdd: 10,
        tarjetaDatosAdd: 15,
        productosAdd: 18,
        serviciosAdd: 19,
        usuariosAdd: 20,
        usuariosProductosAdd: 21,
        usuariosServiciosAdd: 22,

        //Editar        
        empresaEdit: 23,
        empresaLogoEdit: 24,
        empresaPortadaEdit: 25,
        empresaGeoEdit: 26,
        tarjetaDatosEdit: 27,
        tarjetaFotoEdit: 28,
        tarjetaFotoPortada: 29,
        usuariosProductosEdit: 30,
        usuariosServiciosEdit: 31,
        productosEdit: 32,
        serviciosEdit: 33,
        tarjetasEdit: 34,
        usuariosEdit: 35,


        //ELiminar       
        tarjetasDelete: 36,
        usuariosDelete: 37,
        productosDelete: 38,
        serviciosDelete: 39,
        usuariosProductosDelete: 40,
        usuariosServiciosDelete: 41,
    }
}

export function Autorizado(value) {
    if (localStorage.ingreso) {
        if (Array.isArray(value)) {
            for (let i = 0; i < value.length; i++) {
                const val = value[i].toString();
                const usr = usuario().per;
                if (usr.some(e => e === val)) {
                    i = value.length;
                    return true;
                } else {
                    if (i === value.length - 1) {
                        return false;
                    }
                }

            }
        } else {
            return []
        }
    } else {
        return []
    }
}


/*
admin: 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41
usuario: 1,2,3,4,5,6,7,8,9,11,12,13,14,16,17,27,28,29,35,

*/