export default function Select({disabled, name, value, onChange, options, titulo, primerDato}) {
    disabled = disabled === undefined ? false : disabled;    
    return (
        <>
        <div className="form-floating">
            <select className="form-control"
            name={name} 
            value={value}
            onChange={onChange}
            disabled={disabled}
            >
                {
                    primerDato !== undefined && 
                    <option value={primerDato.value}>{primerDato.titulo}</option>
                }
                {
                    options !== undefined ?
                    options.map((v,i) => {
                        return <option key={i} value={v.value}>{v.titulo}</option>
                    }):""
                }

            </select>
            <label>{titulo}</label>
        </div>
        </>
    )
};
