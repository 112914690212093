

export default function SideBarHeader() {
    return (
        <>
        
        <div className="sbh-header">
            <div className="sbh-style">
            <img className="col-10" src={"http://www.infortech.net.co/static/media/logoInfortech.563ff7c5.png"} alt={'Logo Principal INFORTECH SAS'} />  
            </div>
        </div>                  
        </>
    )
};
