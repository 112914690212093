import axios from "axios";
import { Lectura } from "infortech_modules/Constantes/Lectura";
import { api} from "infortech_modules/Constantes/Constantes";
import { usuario } from "infortech_modules/Constantes/Usuarios";
import { header } from "infortech_modules/Constantes/Constantes";

export default function Put({ url, table,  id, caso, reload, data0, data1, data2, data3, data4, data5, data6, key, duplicate, iniciar }) {
    reload = reload === undefined ? false:reload;
    duplicate = duplicate === undefined ? {verificar:false} : duplicate;
    console.log(id);  
    const promesa = new Promise((res, rej) => {      
        axios.put(api + url,
            { table: table, id: id, permisos: usuario().per, caso: caso, duplicate:duplicate,  data0: data0, data1: data1, data2: data2, data3: data3, data4: data4, data5: data5, data6: data6, key: key },
            { headers: header(), timeout:5500 }
            
        ).then((r) => {
            if (r.data.res) {
                res(r.data.msje);
                if(reload){
                    setTimeout(() => {
                        window.location.reload();                    
                    }, 2500);
                }
                
            } else {
                rej(Lectura(r.data.msje, iniciar))
            }
        }).catch((f) => {           
            rej(Lectura(f, iniciar))
        })

    })
    return promesa;
}
