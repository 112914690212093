import Modal from "react-bootstrap/Modal";
export default function Modales({
    show, onHide, size, centered, backdrop, fullscreen, backdropClassName, titulo, classNameTitulo, classNameBody,
    classNameFooter, body, footer, props
}) {
    size = size !== undefined ? size : 'xl';
    centered = centered !== undefined ? centered : true;
    fullscreen = fullscreen !== undefined ? fullscreen : false;

    return (
        <Modal
            {...props}
            show={show}
            onHide={onHide}
            size={size}
            centered={centered}
            backdrop={backdrop}
            fullscreen={fullscreen}
            backdropClassName={backdropClassName}
        >
            {
                titulo !== undefined &&
                <Modal.Header closeButton>
                    <Modal.Title className={classNameTitulo}>{titulo}</Modal.Title>
                </Modal.Header>
            }

            <Modal.Body
                className={classNameBody}
            >
                {body}
            </Modal.Body>
            {
                footer !== undefined && (
                    <Modal.Footer className={classNameFooter}>
                        {footer}
                    </Modal.Footer>
                )
            }


        </Modal>
    )
};
