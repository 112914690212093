import { IoIosArrowForward } from 'react-icons/io';

export default function Rutas(datos) {
    if (Array.isArray(datos)) {
        if (Array.isArray(datos)) {
            const valor = datos.length;
            const info = datos.map((value, i) => {
                return (
                    <li key={i} className="rutas">{value}
                        {valor === i + 1 ? ('') : (
                            <IoIosArrowForward className='pos'/>
                        )}
                    </li>
                )
            })
            return (
                <ul className="list-inline">
                    {info}
                </ul>
            )
        } else {
            return datos;
        }
    }
}
