import Spinner from 'react-bootstrap/Spinner';
import Logo from 'infortech_modules/Recursos/Imagenes/loading.gif';

export default function Carga({ mensaje, fondo, altura, anchoImagen, classNameMensaje, spiner, full, imagen }) {
    mensaje = mensaje !== undefined ? mensaje : ""
    fondo = fondo !== undefined ? fondo : "bg-transparent"
    altura = altura !== undefined ? altura : "0"
    anchoImagen = anchoImagen !== undefined ? anchoImagen : 150
    classNameMensaje = classNameMensaje !== undefined ? classNameMensaje : "text-3xl font-bold"
    spiner = spiner !== undefined ? spiner : true;
    full = full !== undefined ? full : false;
    imagen = imagen === undefined ? true : imagen;

    return (
        <>
            {
                full ?
                    <div className='container container-fluid'>
                        <div className={`w-full`}>
                            <div className={`${fondo}`}>
                                <div className='App-header' style={{ minHeight: '100vh' }}>
                                    <div className='mb-3'>{imagen && <img src={Logo} alt='Logo Empresa' width={anchoImagen} />}</div>
                                    {spiner && <Spinner animation="border" size='xxl' />}
                                    <div className={classNameMensaje}>{mensaje}</div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className={`container container-fluid p-3`}>
                        <div className='max-width-full'>
                            <div className={`shadow-lg  ${fondo} rounded`}>
                                <div className='App-header' style={{ minHeight: altura }}>
                                    <div className='mb-3'><img src={Logo} alt='Logo Empresa' width={anchoImagen} /></div>
                                    {spiner && <Spinner animation="border" size='xxl' />}
                                    <div className={classNameMensaje}>{mensaje}</div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
};
