import { BsXCircleFill, BsCheckCircleFill, BsFillExclamationTriangleFill } from 'react-icons/bs';
import { Spinner } from 'react-bootstrap';

export function Success() {
    return (
        <BsCheckCircleFill
            className='fs-4'
        />
    )
}

export function Warning() {
    return (
        <BsFillExclamationTriangleFill
            className='fs-4'
        />
    )
}

export function Danger() {
    return (
        <BsXCircleFill
            className='fs-4'
        />
    )
}

export function Loading() {
    return (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    )

}