
import EliminarToken from "infortech_modules/Constantes/EliminarToken";
export const Lectura = (r, abrir) => {
    console.log(""+r)
    let retornar; 
    switch (""+r) {
        case "AxiosError: Request failed with status code 404":
            retornar = "(Error 404): Servicio No Encontrado"
            break;
        case "AxiosError: Request failed with status code 401":
           EliminarToken(abrir);
            retornar = "(Error 401): Credenciales No Válidas";            
            break;    
        case "AxiosError: Network Error":
            retornar = "Error: No se pudo establecer la conexión con el servidor"
            break;
        default:
            retornar = r;
            break;
    }
    return retornar;

}

export default Lectura;
