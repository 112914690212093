import { BrowserRouter, Routes, Route } from "react-router-dom"
import Iniciar from "./Iniciar";
import Registrar from "./Registrar";
export default function Inicio({iniciar}) {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Iniciar iniciar={iniciar} />}/>
                <Route path="/Registrar" element={<Registrar iniciar={iniciar} />} />               
                <Route path="*" element={<Iniciar iniciar={iniciar} />} />
            </Routes>
        </BrowserRouter>
    )
};
